import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { findUserShowRatingByListingId, getUserShowRatings } from '../../util/data';
import {
  ResponsiveImage,
  NamedLink,
  InlineTextButton,
  IconReviewStar,
  IconArrowHead,
} from '../../components';

import css from './ReviewsPage.module.css';
import { createSlug } from '../../util/urlHelpers';

const MAX_AVERAGE_REVIEW = 10;

const SectionHero = props => {
  const {
    title,
    richTitle,
    listing,
    showListingInProgress,
    currentUser,
    reviews,
    averageReview,
    organizationProfile,
    listingId,
    coverId,
    posterId,
    onOpenShowRatingModal,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const coverImage = listing?.images?.find(i => i.id.uuid === coverId) ?? firstImage;
  const posterImage = listing?.images?.find(i => i.id.uuid === posterId) ?? firstImage;

  const showRatings = getUserShowRatings(currentUser);
  const showRating = findUserShowRatingByListingId(showRatings, listing.id.uuid);

  // title container
  const titleContainer = (
    <div className={css.titleContainer}>
      {organizationProfile?.id ? (
        <NamedLink
          className={css.organizationProfile}
          name="OrganizationProfilePage"
          params={{ id: organizationProfile.id }}
        >
          {organizationProfile.name}
        </NamedLink>
      ) : null}
      <div className={css.titleAndReviews}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.topReviews}>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ReviewsPage.rating" />
            </span>
            <span className={css.topReviewsValue}>
              <IconReviewStar isFilled />
              <FormattedMessage
                id="ReviewsPage.ratingCount"
                values={{ count: averageReview, maxCount: MAX_AVERAGE_REVIEW }}
              />
            </span>
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ReviewsPage.yourRating" />
            </span>
            {showRating ? (
              <InlineTextButton
                type="button"
                className={css.topReviewsValue}
                onClick={onOpenShowRatingModal}
              >
                <IconReviewStar className={css.showRatingStar} isFilled />
                <FormattedMessage
                  id="ReviewsPage.ratingCount"
                  values={{ count: showRating.rating, maxCount: MAX_AVERAGE_REVIEW }}
                />
              </InlineTextButton>
            ) : (
              <InlineTextButton
                type="button"
                className={css.topReviewsValue}
                onClick={onOpenShowRatingModal}
              >
                <IconReviewStar />
                <FormattedMessage id="ReviewsPage.rateText" />
              </InlineTextButton>
            )}
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ReviewsPage.userReviews" />
            </span>
            <span className={css.topReviewsValue}>{reviews.length}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const returnLink = (
    <NamedLink
      className={css.returnLink}
      name="ListingPage"
      params={{ id: listingId.uuid, slug: createSlug(title) }}
    >
      <IconArrowHead className={css.returnLinkIcon} direction="left" />
      <FormattedMessage id="ReviewsPage.returnText" />
    </NamedLink>
  );

  // top content
  const topContent = <div className={css.topContent}>{returnLink}</div>;

  return (
    <div className={css.sectionHero}>
      <div className={css.heroContent}>
        {topContent}
        <div className={css.listingInfo}>
          <div className={css.listingImage}>
            <ResponsiveImage
              rootClassName={css.listingImageRoot}
              alt={title}
              image={posterImage}
              variants={['poster-crop']}
            />
          </div>
          {titleContainer}
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
